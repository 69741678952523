$(document).ready(function () {

  $('[data-tracking-sheet-rating]').on('change', function () {
    let is_low_rating = $('[data-tracking-sheet-rating]:checked').val() <= 2;

    $('[data-tracking-sheet-feedback-label]')[0].innerHTML = is_low_rating ? `Share Feedback:<sup>*</sup>` : 'Share Feedback:';
  });

  $('[data-tracking-sheet-submit]').on('click', function (e) {
    e.preventDefault();

    let $form = $('[data-tracking-sheet-form]');
    let params = $form.serializeArray();
    let url = $form.attr('action');

    $.ajax({
      dataType: 'json',
      method: 'POST',
      url: url,
      data: params,
      success: function (data) {
        if (data.follow_up) {
          $('[data-tracking-sheet-thank-you-follow-up]').html(`<i>Your MOC Program Contact will follow-up with you shortly.</i>`);
        }
        if (data.stats_ignored) {
          $('[data-tracking-sheet-stats-ignored]').html(`<i>The stats you submited were ignored as another guide has already submitted them.</i>`);
        }
        

        $('[data-tracking-sheet-thank-you]').modal().show();
      },
      error: function (data) {
        let response = JSON.parse(data.responseText);
        
        if (response.errors && response.errors.length > 0) {
          let items = '';
          
          $.each(response.errors, function (index, error) {
            items += `<li>${error}</li>`;
          });

          $('[data-display-errors]').removeClass("d-none");
          $('[data-display-errors]').html(`
            <h4>${response.errors.length} errors prohibited this tracking sheet from being saved:</h4>
            <ul>${items}</ul>
          `);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    });
  });

  $('[data-tracking-sheet-thank-you]').on('hidden.bs.modal', function () {
    window.location.href = '/volunteer/feedback';
  });

  let ts_date_range = $('[data-tracking-sheet-date-range]');

  if (ts_date_range.length) {
    let format = 'MM/DD/YYYY';

    let start_date = ts_date_range.data('start-date');
    let end_date = ts_date_range.data('end-date');

    ts_date_range.daterangepicker({
      startDate: start_date,
      endDate: end_date,
      autoUpdateInput: !!ts_date_range.data('filter-applied'),
      opens: 'center',
      locale: {format: format}
    });

    ts_date_range.on('apply.daterangepicker', function(e, picker) {
      $(this).val(picker.startDate.format(format) + ' - ' + picker.endDate.format(format));
    });
  }
});
