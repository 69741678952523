function setupActionModal() {
  if (this.dataset.status !== "approved" && this.dataset.status !== "rejected") {
    return; // early return if status is neither "approved" nor "rejected"
  }

  const modalId = this.dataset.status === "approved" ? "#ApproveBgCheckModal" : "#RejectBgCheckModal";
  const modal = $(modalId);
  
  $('[data-id=background_check-modal-button').data('url', this.dataset.url);
  $('[data-id=background_check-modal-button').data('status',  this.dataset.status );
  modal.find('[data-volunteerBgCheck]').text(this.dataset.volunteer_name);
  modal.modal('show');

}

function updateBackgroundCheck() {    
  const backgroundChecksList = document.querySelector('#background_checks_list');
  backgroundChecksList.innerHTML = '';

  $.ajax({
    url: $(this).data('url'),
    method: 'PUT',
    dataType: "json",
    data: {
      background_check_status: $(this).data('status')
    },
    success: function(){ window.location.reload(); },
    error: function(){ window.location.reload(); }
  })
}

function setupBackgroundChecksActionsBehavior() {
    document.querySelectorAll("[data-id=background_check-button]").forEach((element) => {
      element.addEventListener('click', setupActionModal)
    })
  
    document.querySelectorAll("[data-id=background_check-modal-button]").forEach((element) => {
      element.addEventListener('click', updateBackgroundCheck)
    })
  }

  function applyFilter() {
    let form = this.closest("form");
    $.ajax({
      dataType: "script",
      type: form.method,
      url: form.action,
      data: $(form).serialize()
    });
  }

 function updateUrlWithSortBy() {
  let sortDropdown = document.querySelector('[data-select2-id="sort_by"]');
  if (!sortDropdown) { return; }
  
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set('sort_by', sortDropdown.value);
  let updatedUrl = window.location.pathname + '?' + searchParams.toString();
  window.history.replaceState({ path: updatedUrl }, '', updatedUrl);
 }

$(document).ajaxComplete(function (event, xhr, settings) {
  if ( settings.url.indexOf("/program_team/background_checks") != -1 && settings.type === "GET") {
    setTimeout(setupBackgroundChecksActionsBehavior, 500)
    updateUrlWithSortBy()
  }
  else if( settings.url.indexOf("/program_team/feedback") != -1 && settings.type === "GET") {
    updateUrlWithSortBy()
  }
});

$(document).ready(function () {
  setupBackgroundChecksActionsBehavior()
  let sortBy = document.querySelector('[data-select2-id="sort_by"]');
  if (sortBy) { sortBy.onchange = applyFilter; }
});
